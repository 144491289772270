<template>
    <div class="my_page">

        <van-row>
            <van-col span="8"></van-col>
            <van-col span="8">
                <van-image class="avatar" :src="avatar" round fit="cover" width="8rem" height="8rem">
                    <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                    </template>
                </van-image>

                <span class="nickname">{{ userInfo.nickName }}</span>
            </van-col>
            <van-col span="8"></van-col>
        </van-row>

        <van-space :size="16" direction="vertical" fill>
            <van-cell-group inset>
                <van-cell title="收藏夹" is-link to="/my/favorite" >
                    <template #value>
                        <van-tag type="danger" round>{{ favoriteCount }}</van-tag>
                    </template>
                </van-cell>
                
                <van-cell title="浏览历史" is-link to="/my/history" >
                    <!-- 使用 title 插槽来自定义标题 -->
                    <template #value>
                        <van-tag type="danger" round>{{ historyCount }}</van-tag>
                    </template>
                </van-cell>
            </van-cell-group>

            <van-cell-group inset v-show="isAdmin">
                <van-cell title="数据地址" :label="sourceDomain" is-link :url="sourceDomain??''"/>
                <van-cell title="获取 最新地址" clickable @click="fetchSourceDomain"/>
                <van-cell title="用户管理" is-link url="/user"/>

                <van-collapse v-model="activeNames">
                    <van-collapse-item title="数据操作" name="1">
                        <van-cell title="备份后端数据" clickable icon="points" @click="backUpAll" />
                        <van-cell title="清除后端数据" clickable icon="delete-o" @click="clearAll" />

                        <van-cell v-for="category in categories" clickable
                            :title="`爬取 ${category.title}`" :key="category.id" 
                            @click="excSpider(category, true)"/>
                    </van-collapse-item>
                </van-collapse>

            </van-cell-group>

            <van-cell-group inset class="exit-button">
                <van-button type="danger" block round @click="onClickLogOut">退出登录</van-button>
            </van-cell-group>
        </van-space>

    </div>
</template>

<script>
import { Dialog } from 'vant'
import { ref } from 'vue';

import { getSourceDomain } from '../../api/auth'
import { callSpider } from '../../api/spider'
import { getUserInfo } from '../../api/user'
import common from '../../utils/common'

export default {
    name: 'MyPage',
    components: {
    },
    setup() {
        const activeNames = ref(['0']);
        return { activeNames };
    },
    data() {
        return {
            defaultAvatar: require("@/assets/logo.png"),
            sourceDomain: '',
            categories: [
                { id: 0, title: '获取域名' },
                { id: 1, title: '在线视频' },
                { id: 2, title: '下载专区' },
                { id: 3, title: '图片专区' },
                { id: 4, title: '美女专区' },
                { id: 6, title: '女优专区' },
                { id: 19, title: 'Ipornbase专区' },
                { id: 20, title: 'A7aYYDS88专区' }
            ],
            userInfo: Object,
            isAdmin: false,
            favoriteCount: 0,
            historyCount: 0
        }
    },
    computed: {
        avatar() {
            if (this.userInfo.avatar && `${this.userInfo.avatar}`.length > 0) {
                return this.userInfo.avatar
            }
            return this.defaultAvatar
        }
    },
    created() {
        this.excSpider({ id: 0, title: '' }, false)
        this.fetchUserInfo()
        this.fetchSourceDomain()
    },
    mounted() {
        this.favoriteCount = common.getArticleFavoriteCount()
        this.historyCount = common.getArticleHistoryCount()
    },
    methods: {
        /**
         * 执行退出登录
         */
        onClickLogOut() {
            Dialog.confirm({
                message:
                '是否确定退出登录？',
            })
            .then(() => {
                // on confirm
                this.$store.dispatch('userLogout')  
                this.$router.replace('/')
            })
            .catch(() => {
                // on cancel
            });
        },

        /**
         * 获取用户信息
         */
        async fetchUserInfo() {
            this.userInfo = await getUserInfo();
            this.isAdmin = (this.userInfo && this.userInfo.roleId == 1)
        },

        /**
         * 获取最新域名
         */
        async fetchSourceDomain() {
            this.sourceDomain = await getSourceDomain()
        },

        /**
         * 备份所有后端数据
         */
        async backUpAll() {
            console.log('备份所有后端数据')
        },

        /**
         * 清除所有后端数据
         */
        async clearAll() {
            console.log('清除所有后端数据')
        },

        /**
         * 执行爬虫
         * @param {object} category 
         */
        async excSpider(category, showMsg = false) {
            let data = await callSpider(category.id)
            if (showMsg && data.code === 200) {
                Dialog.alert({
                    message: `更新 “${category.title}” ${data.msg}`,
                }).then(() => {
                    // on close
                })
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .my_page
        background-color: #f3f3f3
        height: 100%

        .van-row
            background-color: #1989fa
            padding: 40px 0
        
        .avatar
            margin-bottom: 10px
        
        .nickname
            display: block
            color: white
            text-align: center
            font-weight: bold

        .van-space
            padding: 32 * $unit 0

            .exit-button
                background: #00000000
</style>