import request from '../utils/request'

/**
 * 执行爬虫
 * @param {int} id 默认获取域名，1：图片专区，2：在线视频，3：视频下载专区
 * @returns 执行状态
 */
export const callSpider = (id) => {
    let data = ``
    return request.get(`/spider/crawl/${id}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data)
}
